<template>
  <div class="means">
    <PageTopNav
      :tabsShow="true"
      :addShow="true"
      :tabList="tabList"
      @addMeans="addMeans"
      @changeTab="changeTab"
    >
      <p slot="addName">添加农资</p>
    </PageTopNav>
    <div class="outDiv">
      <div class="mains">
        <div class="l-search">
          <!-- <input
            type=""
            class="search-input"
            placeholder="搜索你想要的农资"
            :class="isFocusShow ? 'active' : ''"
            @focus="getFocus"
            @blur="getBlur"
            v-model="title"
          /> -->
          <el-input
            class="search-input"
            :class="isFocusShow ? '' : 'active'"
            v-model.trim="title"
            autocomplete="off"
            placeholder="搜索你想要的农资"
            maxlength="20"
            @blur="blurInput"
          ></el-input>
          <div class="buttons" @click="getSearch">搜索</div>
        </div>
        <div class="lists" v-if="meansList.length">
          <div
            class="items animated fadeInUp"
            v-for="(item, index) in meansList"
            :key="index"
          >
            <div class="col1">
              <img
                :src="
                  item.photo
                    ? uploadImgs + item.photo
                    : require('../assets/image/means/item1.jpg')
                "
                class="bgImgs"
              />
              <div class="tags">{{ item.title }}</div>
              <div class="icon-btn">
                <img
                  class="edits"
                  src="../assets/image/land/edits.png"
                  @click="editMean(item.id)"
                />
                <img
                  class="close"
                  src="../assets/image/land/close.png"
                  v-if="!item.custom_state"
                  @click="enableMeans(item.id, index)"
                />
                <img
                  class="open"
                  src="../assets/image/land/open.png"
                  v-if="item.custom_state"
                  @click="disableMeans(item.id, index)"
                />
              </div>
            </div>
            <div class="col2">
              <div></div>
              <p class="one-txt-cut">
                库存数量：{{ item.stock }} 单位：{{ item.unit }}
              </p>
            </div>
            <div class="col3 txt-cut">
              {{ item.desc }}
            </div>
            <div class="col4">
              <div class="buttons1" @click="setWarehousing(item.id)">入库</div>
              <div class="buttons2" @click="setWarehouse(item.id, item.stock)">
                出库
              </div>
              <div class="buttons3" @click="seeRecord(item.id, item.unit, 1)">
                记录
              </div>
            </div>
          </div>
        </div>
        <div class="lists" v-else>
          <MyNothing></MyNothing>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          v-if="meansList.length"
        >
        </el-pagination>
      </div>
    </div>
    <MyNav ref="MyNav"></MyNav>
    <!-- 出入库 -->
    <el-dialog
      class="small-pop"
      title=" "
      :visible.sync="dialogVisible"
      width="662px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pop-small">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ PopTitle }}
        </div>
        <el-form
          class="forms"
          ref="batchForm"
          :model="batchForm"
          :rules="batchRules"
          hide-required-asterisk
        >
          <el-form-item label="数量" :label-width="formLabelWidth" prop="name">
            <el-input
              v-model.trim="batchForm.name"
              autocomplete="off"
              placeholder="请输入数量"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="inputLab"
            :label-width="formLabelWidth"
            prop="purposeVal"
          >
            <el-input
              maxlength="20"
              v-model.trim="batchForm.purposeVal"
              autocomplete="off"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label=" " class="dialog-footer">
            <el-button
              class="ok-button"
              type="primary"
              @click="okWarehouse('batchForm')"
              >确 定</el-button
            >
            <el-button class="no-button" @click="noWarehouse('batchForm')"
              >取 消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 记录 -->
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="bigDialogShow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="666px"
      :before-close="setRecordClose"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          农资记录
        </div>
        <div
          class="block"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-blockLoadMore="loadMoreTampData"
        >
          <el-empty v-show="!tampList.length" description="暂无记录"></el-empty>
          <el-timeline>
            <el-timeline-item
              :class="item.yank === 0 ? 'active' : ''"
              :timestamp="item.time"
              placement="top"
              v-for="item in tampList"
              :key="item.id"
            >
              <el-card>
                <div class="info-col1">
                  <span>{{ item.mname }}</span>
                  <span>{{ item.yank ? "入库" : "出库" }}</span>
                  <span
                    class="num"
                    :style="item.yank === 1 ? 'color:#67C23A;' : ''"
                    >{{ item.num }}{{ unit }}</span
                  >
                </div>
                <div class="info-col2">
                  <span class="text1">{{
                    item.yank === 0 ? "原因" : "来源"
                  }}</span>
                  <span class="text2">{{ item.desc }}</span>
                  <!-- <span class="text3" v-if="item.yank === 0">管理农事任务</span>
                  <span class="text4" v-if="item.yank === 0">农事任务001</span> -->
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-dialog>
    <!-- 新增/修改农资 -->
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="666px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog()"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ bigPopTitle }}
        </div>
        <div class="addForm">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item label="农资封面" :label-width="formLabelWidth">
              <el-upload
                :class="['uploadCover', imageUrl ? 'active' : '']"
                action="https://resource.darsing.net/api/v1/img"
                name="img"
                :limit="1"
                list-type="picture-card"
                :show-file-list="true"
                :file-list="fileList"
                :headers="uploadHeaders"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarFail"
                :on-remove="removeFile"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="农资名称"
              :label-width="formLabelWidth"
              prop="title"
            >
              <el-input
                v-model.trim="form.title"
                autocomplete="off"
                placeholder="请输入农资名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="农资分类"
              :label-width="formLabelWidth"
              prop="cid"
            >
              <el-select v-model="form.cid" placeholder="种类">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in tabList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="启用"
              :label-width="formLabelWidth"
              prop="custom_state"
            >
              <el-switch class="mySwitch" v-model="form.custom_state">
              </el-switch>
            </el-form-item>
            <el-form-item
              label="农资类型"
              :label-width="formLabelWidth"
              prop="yank"
            >
              <el-radio-group v-model="form.yank">
                <el-radio label="0">消耗品</el-radio>
                <el-radio label="1">重复使用品</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="计量单位"
              :label-width="formLabelWidth"
              prop="unit"
            >
              <el-input
                v-model.trim="form.unit"
                autocomplete="off"
                placeholder="请输入计量单位"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="备注"
              :label-width="formLabelWidth"
              prop="desc"
            >
              <el-input
                v-model.trim="form.desc"
                type="textarea"
                autocomplete="off"
                placeholder="请输入备注"
                maxlength="200"
              >
              </el-input>
            </el-form-item>
            <el-form-item label=" " class="dialog-footer">
              <el-button class="ok-button" type="primary" @click="okAdd('form')"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAdd('form')"
                >取 消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getResourceClassListApi,
  getResourceListApi,
  getResourceEditApi,
  postAddResourceApi,
  getResourceStateApi,
  getAddResourceApi,
  getResourceStockApi,
  getResourceSheetApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      // flag: false,
      loading: false,
      // 上传
      fileList: [],
      imageUrl: "",
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      // 分页
      total: 0, // 总数
      last_page: 1, //末尾页
      per_page: 10, // 一页个数
      current_page: 1, // 当前页
      // 库存记录的分页
      supplyTotal: 0, // 总数
      supplyPer_page: 10, // 一页个数
      supplyCurrent_page: 1, // 当前页
      // 筛选条件
      title: undefined,
      cid: undefined,
      // 农资分类列表
      tabList: [],
      // 农资列表
      meansList: [],
      // 农资出入库记录列表
      tampList: [],
      isFocusShow: true,
      // 入库/出库
      dialogVisible: false,
      // 农资入库/出库
      quantity: "",
      purposeVal: "",
      PopTitle: "",
      inputLab: "",
      //记录
      bigDialogShow: false,
      resource_id: "",
      // 单位
      unit: "",
      //   添加
      addShow: false,
      bigPopTitle: "",
      // 添加农资表单
      form: {
        title: "",
        unit: "",
        cid: "",
        desc: "",
        custom_state: false,
        supplier: "",
        yank: "",
      },
      // 农资ID
      id: "",
      rules: {
        title: [{ required: true, message: "请输入农资名称", trigger: "blur" }],
        desc: [{ required: true, message: "请输入备注", trigger: "blur" }],
        unit: [{ required: true, message: "请输入计量单位", trigger: "blur" }],
        yank: [
          { required: true, message: "请选择农资类型", trigger: "change" },
        ],
        cid: [{ required: true, message: "请选择农资分类", trigger: "change" }],
      },
      // 批次表单
      batchForm: {
        name: "",
        purposeVal: "",
      },
      // 表单校验规则
      batchRules: {
        name: [{ required: true, message: "请输入数量", trigger: "blur" }],
        purposeVal: [
          {
            required: true,
            message: `不能为空`,
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "120px",
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  watch: {
    addShow(newValue) {
      if (!newValue) {
        this.form = {
          title: "",
          unit: "",
          cid: "",
          desc: "",
          custom_state: false,
          supplier: "",
          yank: "",
        };
        this.imageUrl = "";
        this.fileList = [];
      }
    },
  },
  created() {
    this.getResourceClassListApi();
  },
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    blurInput() {
      this.isFocusShow = this.title ? true : false;
    },
    // 删除图片
    removeFile() {
      this.imageUrl = "";
      this.form.photo = "";
    },
    // 上传失败
    handleAvatarFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    // 上传成功
    handleAvatarSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    // 获取农资详情
    async getAddResourceApi() {
      const { code, results } = await getAddResourceApi({
        resource_id: this.id,
      });
      if (code === 0) {
        results.yank = results.yank + "";
        this.form = results;
        this.form.supplier = results.cid;
        this.imageUrl = results.photo;
        if (results.photo && this.fileList.length === 0) {
          this.fileList.push({
            url: this.uploadImgs + results.photo,
          });
        }
        this.form.custom_state
          ? (this.form.custom_state = true)
          : (this.form.custom_state = false);
      }
    },
    // 添加农资
    async postAddResourceApi() {
      this.form.custom_state
        ? (this.form.custom_state = 1)
        : (this.form.custom_state = 0);
      this.form.yank = +this.form.yank;
      const { code } = await postAddResourceApi({
        manor_id: this.farmID,
        photo: this.imageUrl,
        ...this.form,
        weight: 0,
      });
      if (code === 0) {
        this.getResourceListApi();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
      }
    },
    // 修改农资信息
    async getResourceEditApi() {
      this.form.custom_state
        ? (this.form.custom_state = 1)
        : (this.form.custom_state = 0);
      this.form.yank = +this.form.yank;
      const { code } = await getResourceEditApi({
        manor_id: this.farmID,
        resource_id: this.id,
        ...this.form,
        intro: undefined,

        photo: this.imageUrl,
      });
      if (code === 0) {
        this.getResourceListApi();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.$refs["form"].resetFields();
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.current_page = val;
      this.getResourceListApi();
    },
    // 获取农资列表
    async getResourceListApi() {
      showFullScreenLoading();
      const { code, results } = await getResourceListApi({
        manor_id: this.farmID,
        cid: this.cid,
        title: this.title,
        pn: this.current_page,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
        this.meansList = results.data;
      }
      tryHideFullScreenLoading();
    },

    // 获取农资分类列表
    async getResourceClassListApi() {
      const { code, results } = await getResourceClassListApi({
        custom_state: 1,
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.tabList = results.data;
        this.cid = this.$route.query.id || this.tabList[0].id;
        this.getResourceListApi();
      }
    },
    getFocus() {
      this.isFocusShow = true;
    },
    getBlur() {
      this.isFocusShow = false;
    },
    // 修改农资的状态
    async getResourceStateApi(index, status) {
      const { code } = await getResourceStateApi({
        resource_id: this.id,
        custom_state: status,
      });
      if (code === 0) {
        this.meansList[index].custom_state =
          !this.meansList[index].custom_state;
        this.$forceUpdate();
      }
    },
    // 启用农资
    enableMeans(id, index) {
      this.id = id;
      this.getResourceStateApi(index, 1);
      this.$notify({
        title: "成功",
        message: "已启用",
        type: "success",
      });
    },
    // 禁用农资
    disableMeans(id, index) {
      this.id = id;
      this.$confirm("此操作将禁用该农资, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getResourceStateApi(index, 0);
          this.$notify({
            title: "成功",
            message: "已禁用",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    changeTab(cid) {
      this.cid = cid.id;
      this.current_page = 1;
      this.title = undefined;
      this.getResourceListApi();
    },
    noWarehouse(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    okWarehouse(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.PopTitle === "农资入库") {
            this.getResourceStockApi(1);
          } else {
            this.getResourceStockApi(0);
          }
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 搜索
    getSearch() {
      if (!this.title) {
        this.isFocusShow = false;
        this.$notify.error({
          title: "错误",
          message: "请填写内容",
        });
      } else {
        this.getResourceListApi();
      }
    },
    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bigPopTitle === "添加农资") {
            this.postAddResourceApi();
          } else {
            this.getResourceEditApi();
          }
          this.addShow = false;
        } else {
          return false;
        }
      });
    },
    // 编辑农资
    editMean(id) {
      this.id = id;
      this.getAddResourceApi();
      this.bigPopTitle = "编辑农资";
      this.addShow = true;
    },
    // 添加农资
    addMeans() {
      this.bigPopTitle = "添加农资";
      this.addShow = true;
    },
    // 出入库
    async getResourceStockApi(yank) {
      const { code } = await getResourceStockApi({
        resource_id: this.id,
        yank,
        num: +this.batchForm.name,
        desc: this.batchForm.purposeVal,
      });
      if (code === 0) {
        this.getResourceListApi();
        this.batchForm = {
          name: "",
          purposeVal: "",
        };
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
        });
      }
    },
    //   出库
    setWarehouse(id, stock) {
      if (!stock) {
        this.$notify.error({
          title: "错误",
          message: "库存不足",
        });
        return;
      }
      this.id = id;
      this.PopTitle = "农资出库";
      this.inputLab = "用途";
      this.dialogVisible = true;
    },
    //   入库
    setWarehousing(id) {
      this.id = id;
      this.PopTitle = "农资入库";
      this.inputLab = "来源";
      this.dialogVisible = true;
    },
    loadMoreTampData() {
      if (!this.tampList.length) return;
      if (this.tampList.length == this.supplyTotal) return;
      this.supplyCurrent_page++;
      this.seeRecord(this.supplyCurrent_page);
    },
    // 获取记录
    async seeRecord(resource_id, unit, pn = 1) {
      this.loading = true;
      this.unit = unit;
      this.resource_id = resource_id;
      const { code, results } = await getResourceSheetApi({
        resource_id: this.resource_id,
        pn,
      });
      if (code === 0) {
        this.tampList = [...this.tampList, ...results.data];
        this.supplyTotal = results.count;
        this.supplyPer_page = results.ps;
        this.supplyCurrent_page = results.pn;
        this.bigDialogShow = true;
        this.loading = false;
      }
    },
    setRecordClose() {
      this.bigDialogShow = false;
      this.tampList = [];
    },
    setAddClose() {
      this.addShow = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.batchForm = {
        name: "",
        purposeVal: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.means {
  width: 100%;
  min-height: 100vh;
  padding-top: 62px;
  background-color: #071a2c;
  padding: 62px 0 0;
  .outDiv {
    padding: 0 48px;
    .mains {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      background-color: #0e2138;
      padding: 22px 49px;

      .l-search {
        display: flex;
        align-items: center;
        margin-bottom: 27px;

        /deep/.el-input {
          width: 378px;
          margin-right: 52px;
          .el-input__inner {
            width: 100%;
            background-color: #081c30;
            border: 1px solid #385576;
            color: #57708d;
            font-size: 14px;
          }
          &.active {
            .el-input__inner {
              border-color: #f56c6c;
            }
          }
        }
        .buttons {
          margin-left: 20px;
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #3e90e5;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .lists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .items {
          margin: 0 40px 34px 0;
          width: 307px;
          height: 328px;
          background: #081c30;
          border: 1px solid #385576;
          padding: 13px 15px 0;
          display: flex;
          flex-direction: column;

          .col1 {
            width: 100%;
            height: 184px;
            margin-bottom: 14px;
            position: relative;

            .bgImgs {
              width: 100%;
              height: 100%;
            }

            .tags {
              min-width: 108px;
              text-align: center;
              height: 30px;
              line-height: 30px;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              padding: 0 8px;
              position: absolute;
              left: 0;
              top: 12px;
              color: #fff;
              font-weight: Bold;
              font-size: 16px;
              background-color: #6200bd;
            }

            .icon-btn {
              display: flex;
              align-items: center;
              display: none;
              position: absolute;
              right: 13px;
              top: 12px;

              img {
                width: 33px;
                height: 32px;
                cursor: pointer;
                margin-right: 9px;

                :last-of-type {
                  margin-right: 0;
                }
              }
            }
          }

          .col2 {
            width: 100%;
            border-radius: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            > div {
              width: 5px;
              height: 5px;
              background: #a46dd7;
              border-radius: 50%;
              margin-right: 11px;
            }

            p {
              width: calc(100% - 5px);
              height: 20px;
              color: #fff;
              font-size: 14px;
              margin: 0;
            }
          }

          .col3 {
            width: 100%;
            min-height: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a9b8c8;
            line-height: 20px;
            margin-bottom: 10px;
          }

          .col4 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .buttons1,
            .buttons2,
            .buttons3 {
              width: 82px;
              height: 28px;
              border-radius: 3px;
              color: #fff;
              font-size: 14px;
              line-height: 28px;
              text-align: center;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }

            .buttons1 {
              background: #67c23a;
            }

            .buttons2 {
              background: #3e90e5;
            }

            .buttons3 {
              background: #67c23a;
            }
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }

          :hover {
            .icon-btn {
              display: block;
            }
          }
        }
      }
    }
  }

  .small-pop,
  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;

      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      display: flex;
      padding: 0 62px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 666px;
      height: 693px;
      background: url(../assets/image/land/popBgB.png) no-repeat center center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .addForm {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .block {
        width: 100%;
        height: calc(100% - 124px);
        overflow: auto;
        padding: 0 2px;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }

      /deep/.el-card {
        background-color: #081c30;
        border: none;
      }

      /deep/.el-timeline-item__tail {
        border-left: 2px solid #385576;
      }

      /deep/.el-timeline-item__timestamp {
        font-size: 14px;
        color: #fff;
      }

      /deep/.el-card__body {
        width: 450px;
        min-height: 83px;
        padding: 18px 15px;
      }

      /deep/.el-timeline-item__node {
        background-color: #67c23a;
      }

      /deep/.el-timeline-item.active {
        .el-timeline-item__node {
          background-color: #e60000;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          color: #fff;
          margin-right: 16px;
        }

        .num {
          color: #e60000;
        }

        .num.active {
          color: #67c23a;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }
  .small-pop {
    /deep/.el-dialog {
      width: 662px !important;
    }
  }
  /deep/.uploadCover {
    width: 158px;
    height: 105px;
    display: flex;
    .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 105px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 158px;
      height: 105px;
    }
    &.active {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
